@import "../style.scss";

.modalHeader {
  border-bottom: none;
  justify-content: center;
  color: $primary-font;

  h5 {
    font-size: 25px;
  }
}

.modalBody {
  font-size: 16px;
  color: $primary-font;
  text-align: center
}

.modalFooter{
  @extend .center;

  border-top: none;
  font-size: 20px;
}

.confirmButton {
  @extend .ButtonPrimary;
  width: 100%;
}

.cancelButton {
  @extend .ButtonSecondary;
  width: 100%;
}