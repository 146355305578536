@import "../style.scss";

.containerLayout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.mainLayout {
    flex: 1;
}

.galleryContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 20rem));
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
}

.galleryContainer > img {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}
