@import "../style.scss";

.leafletContainer {
  height: 400px;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

.leafletErrorContainer {
  width: 50%;
  height: 400px;
  width: 100%;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  cursor: pointer;
}

.card {
  width: 50%;
  margin-top: 5%;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
}

.ButtonPrimary {
  @extend .ButtonPrimary;
  width: 100%;
  margin-top: 1rem;
}

.ButtonSecondary {
  @extend .ButtonSecondary;
  width: 100%;
  margin-top: 1rem;
}
