$background-color: #f9f9f9;
$header-color: #ffff;
$footer-color: #013237;
$select-color: rgba(1, 50, 55, 0.3);
$select-option-color: rgba(1, 50, 55, 0.75);
$border-select-color: rgba(1, 50, 55, 0.25);

$primary-line: #ccd6d7;
$secondary-line: #000000;

$primary-font: #013237;
$secondary-font: #4ca771;
$tertiary-font: #eaf9e7;
$quaternary-font: #e8e8e8;
$quinary-font: #a9a9a9;

$primary-button: #4ca771;
$primary-hover-button: #095227;
$secondary-button: #f1f1f1;
$secondary-hover-button: #979797;
$tertiary-button: #dc352c;
$tertiary-hover-button: #b80000;
