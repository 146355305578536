@import "../style.scss";

.container {
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 1rem;
  background-color: $footer-color;
  color: $primary-font;
  height: 72px;
}

.logo {
  height: 2.5rem;
  margin: 0rem 0.75rem;
  &:hover {
    cursor: pointer;
  }
}