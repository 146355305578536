@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.card {
  @extend .card;
  padding: 1rem;
}

.title {
  @extend .title;
  color: $primary-font;
  font-size: 2rem;
  align-items: center;
  padding: 0 0 2rem;

  .titleCol {
    @extend .left;
    flex-direction: column;
    align-items: flex-start;
  }

  .name {
    display: flex;
    align-items: center;
    font-size: 40px;

    :first-child {
      margin-right: 1rem;
    }
  }
}

.subtitle {
  @extend .title;
  color: $quinary-font;
  font-size: 1rem;
  padding: 0;
}

.background {
  padding: 2rem;
}

.col {
  @extend .left;
  padding-top: 0.5rem;
  padding-left: 1rem;
}

.navLinkActive {
  font-weight: 600;
  color: $secondary-font !important;
  border-bottom: 2px solid $secondary-font !important;
  background-color: transparent !important;
  border-color: transparent transparent $secondary-font !important;
}

.navLink {
  font-weight: 600;
  color: black !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.cardTitle {
  border-bottom: 1px solid $primary-line;
}

.status {
  @extend .right;
  color: $quinary-font;
}

.gardenName {
  @extend .left;
  font-size: 18px;
  font-weight: 600;

  .iconContainer {
    width: 100%;
  }
}

.nav {
  padding-top: 2rem;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: overlay;
  overflow-y: hidden;
}

.deleteButton {
  @extend .ButtonSecondary;
  background-color: $tertiary-button;
  color: $tertiary-font;
  height: fit-content;
}

.editButton {
  @extend .ButtonPrimary;
  height: fit-content;
}

.cardItem {
  margin-top: 5px;
}

.iconContainer {
  display: flex;
  align-items: baseline;
  width: 30%;

  :first-child {
    margin-right: 0.5rem;
  }
}

.inputContainer {
  width: 40%;

  @include media("<=tablet") {
    width: 100%;
  }
}

.buttonsContainer {
  :first-child {
    margin-right: 0.5rem;
  }

  @include media("<=tablet") {
    padding: 1rem 0 0rem;
    width: 100%;

    display: flex;
    justify-content: flex-end;
  }
}

.cardGardenItem {
  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(204, 148, 148, 0.2);
    cursor: pointer;
  }
}

.emptyList {
  padding-top: 25px;
}

.date {
  @extend .right;
  color: $secondary-font;
  font-weight: 700;
  font-size: 12px;
  margin-top: 5px;
}

.address {
  @extend .left;

  .iconContainer {
    width: 100%;
  }
}

.description {
  text-align: left;
  max-height: 70px;
  overflow-y: overlay;

  @include media("<=tablet") {
    max-height: 50px;
  }
}

.iconEdit {
  color: $primary-font;
  flex: 0;
  margin-left: 0.5rem;
  font-size: 14px;
  &:hover {
    cursor: pointer;
    color: $primary-button;
  }
}

.iconDelete {
  color: $primary-font;
  flex: 0;
  margin-left: 0.5rem;
  font-size: 14px;
  &:hover {
    color: $tertiary-button;
    cursor: pointer;
  }
}

.dateIcons {
  @extend .right;
  padding-bottom: 0.75rem;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  cursor: pointer;
}

.cardReferenceItem {
  margin-top: 5px;
  display: flex;
}

.cardColItem {
  margin-top: 5px;
  display: flex;
}

.alignLeft {
  text-align: left;
}
