@import "../style.scss";

.card {
  @extend .card;
  width: 40rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  @extend .title;
  @extend .left;
  font-size: 20px;
}

.subtitle {
  @extend .title;
  @extend .left;
  text-align: left;
  padding-top: 0px;
  font-size: 16px;
  color: $secondary-font;
}

.buttonGroup {
  padding-top: 1rem;
  display: flex;
}

.ButtonSecondary {
  @extend .ButtonSecondary;
  width: 100%;
  margin-left: 3px;
  margin-right: 3px;
}

.ButtonPrimary {
  @extend .ButtonPrimary;
  width: 100%;
  margin-left: 3px;
  margin-right: 3px;
}

.invalidFeedback {
  text-align: left;
}

.labelContainer {
  display: flex;
  align-items: center;

  color: $primary-font;

  .required {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
  }
}

.Form {
  padding: 1rem;

  .FormGroup {
    padding-bottom: 1rem;
  }

  .Button {
    padding: 1rem;
  }

  .Condition {
    font-weight: 600;
    font-size: 0.9rem;
  }
}
