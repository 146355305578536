@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.card {
  @extend .card;
  width: 80rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
}

.cardItem {
  border: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  margin-bottom: 3px;

  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.row {
  padding: 1rem;
}

.col {
  padding-bottom: 1rem;
  display: inline-grid;
}

.filter {
  @extend .title;
  @extend .left;
  font-size: 1.2rem !important;
}

.colItem {
  @extend .left;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0rem;
  padding-bottom: 1%;
  margin-bottom: 1rem;
  padding-left: 0.25rem;
}

.borderBottom {
  display: flex;
  border-bottom: 1px solid $primary-line;
  padding: 2.5% 4.5%;
}

.gardenTitle {
  @extend .left;
  color: $primary-font;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0px;
}

.title {
  @extend .left;
  @extend .title;
  color: $primary-font;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1.5rem;
  padding: 0rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(1, 50, 55, 0.2);
  align-items: center;
}

.backgroundInfo {
  padding: 1rem;
}

.line {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $primary-line;
}

.paddingTop {
  padding-top: 2rem;
}

.borderBottom {
  display: flex;
  border-bottom: 1px solid $primary-line;
  padding: 2.5% 4.5%;
}

.infoGarden {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
}

.iconInfo {
  display: flex;
  width: fit-content;
  align-items: center;
  font-weight: 500;
}

.itemInfo {
  margin-bottom: 0.75rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.info {
  max-width: calc(100% - 36px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px;
  width: fit-content;
}

.contains {
  margin-left: 0.5rem;
}

.container {
  margin: 0rem;
}

@media screen and (max-width: 768px) {
  .right {
    height: fit-content;
    padding: 0px;
  }

  .ButtonPrimary {
    font-size: 14px;
  }

  .gardenTitle {
    font-size: 24px;
  }

  .gardenGroup {
    margin-top: 6%;
  }

  .itemInfo {
    font-size: 14px;
  }

  .colItem {
    font-size: 14px;
  }

  .infoGarden {
    flex-direction: column;
  }
}

.marginCol {
  margin-bottom: 10px;
}
