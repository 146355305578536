@import "../style.scss";

.card {
  @extend .card;
  width: 30rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Form {
  padding: 1rem;

  .FormGroup {
    padding-bottom: 1rem;
  }

  .Button {
    padding: 1rem;
  }

  .Condition {
    font-weight: 600;
    font-size: 0.9rem;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  
  color: $primary-font;
  
  .required {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
  }
}

.arrow {
  color: $primary-button;
  display: flex;
  justify-content: flex-start;
  font-size: 25px
}

.title {
  @extend .title;
  color: $secondary-font;
  font-size: 36px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.invalidFeedback {
  text-align: left;
}