@import "../style.scss";

.list {
  padding-top: 5%;
  padding-bottom: 5%;
}

.emptyList {
  margin-top: 5%;
  background-color: rgba(255, 255, 255, 0.8);
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../images/huerta.jpeg");
  width: 100%;
  min-height: calc(100vh - 149px);
}

.card {
  @extend .card;
  width: 80rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 20px;
}
