@import "../../style.scss";

.card {
  @extend .card;
  padding: 0rem;
  width: 100%;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.5);
}

.title {
  @extend .title;
  color: $primary-font;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1.5rem;
  padding: 0rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(1, 50, 55, 0.2);
  align-items: center;
}

.cardItem {
  padding: 1rem;
}
.borderTop {
  border-top: 1px solid $secondary-line;
}

.eventTitle {
  @extend .left;
  color: $primary-font;
  font-size: 18px;
  font-weight: 500;
  padding: 0px;
  text-align: left;
  flex-direction: column;
}

.creatorEmail {
  font-size: 14px;
  font-weight: 400;
}

.updateDate {
  @extend .left;
  text-align: left;
  font-size: 0.75rem;
  color: $secondary-font;
  align-items: center;
  padding: 0px;
  font-weight: 700;
}

.infoEvent {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.iconInfo {
  display: flex;
  width: fit-content;
  align-items: center;
  font-weight: 500;
}

.itemInfo {
  margin-bottom: 4%;
  margin-left: 0rem;
  margin-right: 0rem;
}

.bodyEvent {
  margin: 3%;
}

.eventInfoItem {
  margin-left: 0px;
  margin-right: 0px;
}

.eventGroup {
  padding: 0px 2%;
}

.info {
  max-width: calc(100% - 36px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px;
  width: fit-content;
}

.linkBtnPrimary {
  font-size: 0px;
}

.itemDescription {
  @extend .left;
  text-align: left;
  margin-top: 10px;

  max-height: 70px;
  overflow-y: overlay;
}

.itemAddress {
  @extend .right;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .itemAddress {
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .background {
    padding: 3%;
  }
}

.titleDate {
  @extend .title;
  @extend .left;
  color: $secondary-font;
  font-size: 15px;
  padding: 0rem;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  cursor: pointer;
}

.infoContainer {
  display: flex;
  overflow: auto;

  .info {
    margin-left: 0.25rem;
  }
}

.buttonDisplay {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.eventItem {
  padding: 1rem;
  width: 100%;
  min-height: 80px;
}
