@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.card {
  @extend .card;
  padding: 1rem;
}

.arrow {
  color: $primary-button;
  display: flex;
  justify-content: flex-start;
}

.title {
  @extend .title;
  color: $primary-font;
  font-size: 2rem;
  padding-bottom: 0;
  align-items: center;
}

.background {
  padding: 2rem;
}

.subtitle {
  @extend .title;
  color: $quinary-font;
  font-size: 1rem;
  padding-top: 0;
  padding-bottom: 1rem;
}

.status {
  @extend .right;
  font-size: 20px;
  color: $quinary-font;
}

.backgroundInfo {
  padding: 1rem;
}

.line {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $primary-line;
}

.paddingTop {
  padding-top: 2rem;

  @include media("<=tablet") {
    padding: 0.5rem;
  }
}

.titleItem {
  @extend .title;
  padding: 0rem;
  color: $primary-font;
  font-size: 1.2rem;
}

.styleItem {
  @extend .left;
  padding-top: 1rem;
  color: $secondary-font;
  font-weight: 500;
}

.alignLeft {
  @extend .left;
  text-align: left;
}

.nav {
  padding-top: 2rem;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: overlay;
  overflow-y: hidden;
}

.navLinkActive {
  font-weight: 600;
  color: $secondary-font !important;
  border-bottom: 2px solid $secondary-font !important;
  background-color: transparent !important;
  border-color: transparent transparent $secondary-font !important;
}

.navLink {
  font-weight: 600;
  color: black !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.edit {
  @extend .right;
  color: $secondary-font;
}

.leftCol {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;

  &.isEditing {
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-start;

    margin-top: 0.5rem;

    :first-child {
      margin-right: 0.25rem;
    }
  }
}

.infoContainer {
  display: flex;
  overflow: auto;

  .info {
    margin-left: 0.25rem;
  }
}

.label {
  :first-child {
    margin-left: 0.25rem;
  }
}

.labelContainer {
  display: flex;
  align-items: center;

  color: $primary-font;

  .required {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
  }
}

.invalidFeedback {
  text-align: left;
}

.inputCheckbox {
  text-align: left;
}

.iconContainer {
  width: 1.5rem;
}

.formGroup {
  padding-bottom: 0.5rem;
}

.buttonArticle {
  padding-top: 1rem;
}

.rowUsers {
  @extend .paddingTop;
  margin: 0rem;
}

.userItem {
  display: flex;
  padding: 0.5rem 1.5rem;
  margin: 0rem;
  align-items: center;
  justify-content: space-between;

  @include media("<=tablet") {
    padding: 0.5rem 0rem;
  }
}

.userInfoItem {
  text-align: left;
}

.buttonDelete {
  @extend .ButtonPrimary;
  background-color: $tertiary-button;
  margin: 0rem;

  @include media("<=tablet") {
    font-size: 14px;
  }
}

.userItemButtons {
  display: flex;
  justify-content: space-evenly;

  @include media("<=tablet") {
    flex-direction: column;
  }
}

.userRequestItem {
  padding-bottom: 0.5rem;
}

.userRequestItemButtons {
  display: flex;
  justify-content: flex-end;
}

.buttonPrimary {
  @extend .ButtonPrimary;
  margin-right: 3px;
}

.buttonAceptar {
  @extend .ButtonPrimary;

  @include media("<=tablet") {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}

.buttonRechazar {
  @extend .ButtonSecondary;

  @include media("<=tablet") {
    font-size: 14px;
  }
}

.description {
  text-align: left;
  width: 100%;

  max-height: 200px;
  overflow-y: overlay;

  @include media("<=tablet") {
    max-height: 150px;
  }
}

.imageContent {
  margin-bottom: 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  min-width: 8.8rem;
}

.deleteButton {
  @extend .ButtonSecondary;
  background-color: $tertiary-button;
  color: $tertiary-font;
  margin-bottom: 3px;
  height: fit-content;
}

.deleteButton:hover {
  background-color: $tertiary-hover-button;
}

.joinButton {
  @extend .ButtonPrimary;
  margin-bottom: 3px;
}

.modifyButton {
  @extend .ButtonPrimary;
  margin-bottom: 3px;
}

.updateDate {
  @extend .right;
  margin: 0px;
  text-align: right;
  color: $primary-font;
  opacity: 0.5;
  font-size: 16px;
  font-weight: 500;

  @include media("<=tablet") {
    padding-top: 1rem;
  }
}

.gardenImage {
  &:hover {
    cursor: pointer;
  }
}

.checkbox {
  &:hover {
    cursor: pointer;
  }
}

.colFormat {
  display: grid;
  justify-items: stretch;
}

.styleAccepted {
  color: $secondary-font;
}

.styleNotAccepted {
  color: $quinary-font;
}
