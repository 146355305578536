@import "../style.scss";

.card {
  @extend .card;
  width: 80rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 2rem;
}

.title {
  @extend .title;
  color: $primary-font;
}

.select {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 1px solid $border-select-color !important;
  border-color: $select-color !important;
  color: $select-color;
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 17px;
}

.select:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.input {
  @extend .input;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 1px solid $border-select-color !important;
  border-color: $select-color !important;
  color: $select-color;
  width: 100%;
  height: 40px;
}

.input::placeholder {
  color: $select-color !important;
  font-size: 17px;
  font-weight: 500;
}

input[type="text"] {
  color: $select-color !important;
  font-size: 17px;
  font-weight: 500;
}

.input:focus {
  border: transparent;
}

.ButtonPrimary {
  @extend .ButtonPrimary;
  width: 100%;
}

.marginCol {
  margin-bottom: 0.5rem;
}

.selectOption {
  @extend .select;
  color: $select-option-color;
}