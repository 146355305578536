@import "../../style.scss";

.navLinkActive {
  font-weight: 600;
  color: $secondary-font !important;
  border-bottom: 2px solid $secondary-font !important;
  background-color: transparent !important;
  border-color: transparent transparent $secondary-font !important;
}

.navLink {
  font-weight: 600;
  color: black !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.nav {
  padding-top: 1rem;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: overlay;
  overflow-y: hidden;
  background-color: white;
}
