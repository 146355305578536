@import "../style.scss";

.card {
  @extend .card;
  width: 30rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Form {
  padding: 1rem;

  .FormGroup {
    padding-bottom: 1rem;
  }

  .Button {
    padding: 1.5rem 2rem 2rem;
    border-bottom: 1px solid $primary-line;
  }
}

.Bottom {
  padding: 1rem;
  font-weight: 600;
  font-size: 25px;
}

.title {
  @extend .title;
  color: $secondary-font;
  font-size: 36px;
}

.labelContainer {
  display: flex;
  align-items: center;
  
  color: $primary-font;
  
  .required {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
  }
}

.forgotPassword {
  @extend .center;
  color: $secondary-font;
  cursor: pointer;
  font: {
    size: 18px;
    weight: 500;
  }

  &:hover {
    color: $primary-hover-button;
  }
}

.createUserButton{
  @extend .ButtonPrimary;
  margin-bottom: 2rem;
  font-size: 18px;
}