@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.card {
  @extend .card;
  padding: 0rem;
  margin-bottom: 1.5rem;
  width: 100%;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.5);

  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.arrow {
  color: $primary-button;
  display: flex;
  justify-content: flex-start;
}

.title {
  @extend .title;
  color: $primary-font;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1.5rem;
  padding: 0rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(1, 50, 55, 0.2);
  align-items: center;
}

.subtitle {
  @extend .title;
  color: $quinary-font;
  font-size: 1rem;
}

.backgroundInfo {
  padding: 1rem;
}

.line {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $primary-line;
}

.paddingTop {
  padding-top: 2rem;
}

.titleItem {
  @extend .title;
  padding: 0rem;
  color: $primary-font;
  font-size: 1.2rem;
}

.styleItem {
  @extend .left;
  padding-top: 1rem;
  color: $secondary-font;
  font-weight: 500;
}

.paddingCard {
  border-bottom: 1px solid $primary-line;
  padding-top: 1%;
  padding-bottom: 1%;
}

.borderBottom {
  display: flex;
  border-bottom: 1px solid $primary-line;
  padding: 2.5% 4.5%;
}

.gardenTitle {
  @extend .left;
  color: $primary-font;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0px;
}

.updateDate {
  @extend .right;
  font-size: 0.75rem;
  color: $secondary-font;
  opacity: 0.5;
  align-items: center;
  padding: 0px;
}

.colItem {
  @extend .left;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
}

.infoGarden {
  display: flex;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 3%;
  padding-bottom: 1.5%;
  justify-content: space-between;
}

.iconInfo {
  display: flex;
  width: fit-content;
  align-items: center;
  font-weight: 500;
}

.itemInfo {
  margin-bottom: 4%;
  margin-left: 0rem;
  margin-right: 0rem;
}

.bodyGarden {
  margin: 3%;
  
  &.loading {
    display: flex;
    justify-content: center;
  }
}

.gardenInfoItem {
  margin-left: 0px;
  margin-right: 0px;
}

.gardenGroup {
  padding: 0px 2%;
}

.gardenMap {
  padding: 0px 1.5%;
}

.info {
  max-width: calc(100% - 36px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px;
  width: fit-content;
}

.linkBtnPrimary {
  font-size: 0px;
}

@media screen and (max-width: 768px) {
  .left {
    font-size: 28px;
    height: fit-content;
    padding: 0px;
  }
  .right {
    height: fit-content;
    padding: 0px;
  }
  .ButtonPrimary {
    font-size: 14px;
  }
  .updateDate {
    display: none;
  }
  .gardenTitle {
    font-size: 24px;
  }
  .infoGarden {
    flex-direction: column;
  }
  .gardenGroup {
    margin-top: 6%;
  }
  .itemInfo {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .background {
    padding: 3%;
  }
}

.imgContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.emptyListImg {
  height: 90%;
  vertical-align: middle;
  align-self: center;
  padding-top: 60px;

  @include media("<=tablet") {
    height: 60%;
  }
}

.infoContainer {
  display: flex;
  overflow: auto;

  .info {
    margin-left: 0.25rem;
  }
}

.borderTop {
  border-top: 1px solid $secondary-line;
}

.gardenItem {
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  min-height: 80px;
}

.alignLeft {
  text-align: left;
}

.selectItem:hover {
  border-bottom: 1px solid black;
}

.titleDate {
  @extend .title;
  @extend .left;
  color: $secondary-font;
  font-size: 15px;
  padding: 0rem;
}

.buttonDisplay {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.buttonWidth {
  width: auto;
}

@media screen and (max-width: 767px) {
  .buttonWidth {
    width: 100%;
  }
}

.buttonRight {
  text-align: right;

  @include media("<=tablet") {
    text-align: left;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  cursor: pointer;
}

.Form {
  padding: 1rem;

  .FormGroup {
    padding-bottom: 1rem;
  }

  .Button {
    padding: 1rem;
  }
}

.titleModal {
  font-family: "Roboto", sans-serif;
  padding: 1rem;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  width: fit-content;
}

.cardModal {
  position: absolute;
  z-index: 1001;
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
