@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.title {
  @extend .title;
  color: $primary-font;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1.5rem;
  padding: 0rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(1, 50, 55, 0.2);
  align-items: center;
}

@media screen and (min-width: 768px) {
  .background {
    padding: 3%;
  }
}

.bodyHelp {
  margin: 2.25rem;
  font-weight: 500;
  text-align: left;

  @include media("<=tablet") {
    justify-content: center;
  }
}

.mail {
  color: $secondary-font;
  width: fit-content;
}