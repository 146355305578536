@import "../style.scss";

.card {
  @extend .card;
  position: absolute;
  z-index: 1001;
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cardRegister {
  @extend .card;
  position: absolute;
  z-index: 1002;
  width: 50%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
  .cardRegister {
    width: 100%;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  cursor: pointer;
}

.styleItem {
  @extend .left;
  padding-top: 0.5rem;
  color: $secondary-font;
  font-weight: 500;
  font-size: 20px !important;
  margin-bottom: 0.5rem;
}

.Form {
  padding: 1rem;

  .FormGroup {
    padding-bottom: 1rem;
  }

  .Button {
    padding: 1rem;
  }
}

.Bottom {
  padding: 1rem;
  font-weight: 600;
  font-size: 25px;
}

.title {
  @extend .title;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
}

.labelContainer {
  display: flex;
  align-items: center;

  color: $primary-font;

  .required {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
  }
}

.invalidFeedback {
  text-align: left;
}

.subtitle {
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}

.cardItem {
  padding: 1rem;
}

.cardArticleItem {
  border: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: inherit;
}

.borderTop {
  border-top: 1px solid $secondary-line;
}

.articleTitle {
  @extend .title;
  font-size: 20px;
  font-weight: 600;
}

.background {
  padding: 3%;
}

.ButtonSecondary {
  @extend .ButtonSecondary;
  width: 100%;
}

.ButtonPrimary {
  @extend .ButtonPrimary;
  width: 100%;
}

.buttonCollapse {
  cursor: pointer;
  color: $quinary-font;
  background-color: transparent !important;
  border: transparent !important;
}

.buttonCollapse:hover {
  cursor: pointer;
  color: $secondary-font;
  background-color: transparent !important;
  border: transparent !important;
}

.body {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.paddingTop {
  padding-top: 1rem;
}

.notFoundTitle {
  font-size: 80px;
  color: $secondary-font;
}

.colCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notFoundButton {
  @extend .ButtonPrimary;
  width: 80%;
}
