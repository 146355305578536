@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.card {
  @extend .card;
  padding: 1rem;
  width: 100%;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.5);
}

.title {
  @extend .title;
  color: $primary-font;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1.5rem;
  padding: 0rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(1, 50, 55, 0.2);
  align-items: center;
}

.cardItem {
  padding: 1rem;
  cursor: pointer;
}

.borderTop {
  border-top: 1px solid $secondary-line;
}

.subtitle {
  font-size: 16px;
  text-align: left;
  font-weight: 500;

  @include media("<=tablet") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.bodyEvent {
  margin: 1rem;
}

@media screen and (min-width: 768px) {
  .background {
    padding: 3%;
  }
}

.deleteButton {
  @extend .ButtonSecondary;
  background-color: $tertiary-button;
  color: $tertiary-font;
  height: auto;
  margin-left: 0.8rem;
  width: 100%;

  @include media("<=tablet") {
    margin: 0rem;
  }
}

.deleteButton:hover {
  @extend .ButtonSecondary;
  color: $background-color;
  background-color: $tertiary-hover-button;
}

.moderatorTrueButton {
  @extend .ButtonPrimary;
  height: fit-content;
  width: 100%;
}

.moderatorFalseButton {
  @extend .ButtonPrimary;
  height: fit-content;
  background-color: $secondary-button;
  color: $quinary-font;
  width: 100%;
}

.moderatorFalseButton:hover {
  @extend .ButtonPrimary;
  background-color: $secondary-hover-button;
  color: $background-color;
}

.infoContainer {
  display: flex;
  overflow: auto;

  .info {
    margin-left: 0.25rem;
  }
}

.iconContainer {
  width: 1.5rem;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
}

.searchIcon {
  z-index: 1;
  margin-left: -40px !important;
  height: 100% !important;
  display: inherit !important;
}

.iconGroupText {
  border: 0px;
  background: transparent;
}

.inputSearch {
  height: 100%;
}

.paddingSearch {
  padding: 0.5rem 1rem;
}

.buttonDisplay {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
}

.buttonWidth {
  display: flex;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .buttonDisplay {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .buttonWidth {
    width: 100%;
    display: flex;
    height: 100%;
    margin-top: 0.4rem;
  }
}
