@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.container {
  background-color: $header-color !important;
  color: $primary-font;
  padding: 0.5rem;
}

.containerClosed {
  @extend .container;
  height: 77px;
}

.collapse {
  justify-content: flex-end;
}

.title {
  @extend .title;
  @extend .left;
  font-size: 1.5rem;
  padding: 0rem;
  color: $primary-font;
}

.active {
  color: $secondary-font;
}

.menuItem {
  @extend .subtitle;
  flex: 1;
}

.menuItemAbout {
  @extend .menuItem;
  flex: 3;
}

@media (min-width: 767px) {
  .active {
    border-bottom: 3px solid $secondary-font;
  }

  .menuItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .menuItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding: 1%;
    font-weight: 500;
    border-bottom: 1px solid $primary-line;
  }
}

.navbarMenu {
  color: $header-color !important;
  font-weight: 400 !important;
  background-color: $primary-button !important;

  @include media("<tablet") {
    margin-right: 0.7rem;
  }
}

.dropdownLink {
  text-decoration: none;
}

.toggle {
  display: block;
  color: inherit;
  text-decoration: inherit;
  background-color: inherit;
  border: transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.rowHeader {
  margin-right: 0rem;
  justify-content: right;

  @include media("<tablet") {
    flex-direction: column;
  }
}

.buttonHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem;
  flex: 1;
  margin: 0rem 1rem;
}

.appTitle {
  @extend .title;
  font-size: 34px;
  font-weight: bold;
  margin-left: 2.5rem;
  letter-spacing: 0.4rem;

  @include media("<tablet") {
    margin-left: 0.7rem;
  }
}

.userTitle {
  min-width: 80px;
}

.logo {
  width: 60px;
  margin-left: 20px;
}
