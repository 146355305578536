@import "../../style.scss";
@import "../../../../public/styles/breakpoints.scss";

.card {
  @extend .card;
  padding: 0rem;
  margin-bottom: 1.5rem;
  width: 100%;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.5);

  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.title {
  @extend .title;
  color: $primary-font;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1.5rem;
  padding: 0rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(1, 50, 55, 0.2);
  align-items: center;
}

.borderBottom {
  display: flex;
  border-bottom: 1px solid $primary-line;
  padding: 2.5% 3%;

  @include media("<=tablet") {
    padding: 2.5% 4.5%;
  }
}

.eventTitle {
  @extend .left;
  color: $primary-font;
  font-size: 18px;
  font-weight: 500;
  padding: 0px;
}

.updateDate {
  @extend .right;
  font-size: 0.75rem;
  color: $secondary-font;
  align-items: center;
  padding: 0px;
  font-weight: 700;
}

.infoEvent {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.iconInfo {
  display: flex;
  width: fit-content;
  align-items: center;
  font-weight: 500;
}

.itemInfo {
  margin-bottom: 4%;
  margin-left: 0rem;
  margin-right: 0rem;
}

.bodyEvent {
  margin: 3%;

  &.loading {
    display: flex;
    justify-content: center;
  }
}

.eventInfoItem {
  margin-left: 0px;
  margin-right: 0px;
}

.eventGroup {
  padding: 0px 2%;
}

.info {
  max-width: calc(100% - 36px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px;
  width: fit-content;
}

.linkBtnPrimary {
  font-size: 0px;
}

.itemDescription {
  @extend .left;
  text-align: left;
  width: 100%;
  max-height: 70px;
  overflow-y: overlay;
}

.itemAddress {
  @extend .left;
  text-align: left;
  width: 100%;
  margin-bottom: 0.75rem;
  justify-content: space-between;

  @include media("<=tablet") {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .ButtonPrimary {
    font-size: 12px;
  }

  .itemAddress {
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .background {
    padding: 3%;
  }
}

.titleDate {
  @extend .title;
  @extend .left;
  color: $secondary-font;
  font-size: 15px;
  padding: 0rem;
}

.iconEdit {
  @extend .edit;
  color: $primary-font;
  flex: 0;
  margin-left: 1rem;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}

.iconDelete {
  @extend .delete;
  color: $primary-font;
  flex: 0;
  margin-left: 1rem;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  cursor: pointer;
}

.icon {
  margin-right: 0.4rem;
}

.itemIcon {
  display: flex;
}

.isContest {
  opacity: 0.5;
  font-weight: 450;
}