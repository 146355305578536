@import "../style.scss";

.card {
  @extend .card;
  position: absolute;
  z-index: 1001;
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cardRegister {
  @extend .card;
  position: absolute;
  z-index: 1002 !important;
  width: 50%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .card {
    margin-top: 0px;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  .cardRegister {
    margin-top: 0px;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  cursor: pointer;
}

.styleItem {
  @extend .left;
  padding-top: 0.5rem;
  color: $secondary-font;
  font-weight: 500;
  font-size: 20px !important;
  margin-bottom: 0.5rem;
}

.Form {
  padding: 1rem;

  .FormGroup {
    padding-bottom: 1rem;
  }

  .Button {
    padding: 1rem;
  }
}

.Bottom {
  padding: 1rem;
  font-weight: 600;
  font-size: 25px;
}

.title {
  @extend .title;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
}

.labelContainer {
  display: flex;
  align-items: center;

  color: $primary-font;

  .required {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
  }
}

.invalidFeedback {
  text-align: left;
}

.subtitle {
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}

.cardItem {
  padding: 1rem;
}

.cardArticleItem {
  border: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: inherit;
}

.borderTop {
  border-top: 1px solid $secondary-line;
}

.articleTitle {
  @extend .title;
  font-size: 20px;
  font-weight: 600;
}

.background {
  padding: 3%;
}

.ButtonPrimary {
  @extend .ButtonPrimary;
  width: 100%;
  margin-top: 3px;
}

.ButtonSecondary {
  @extend .ButtonSecondary;
  width: 100%;
  margin-top: 3px;
}

.buttonCollapse {
  cursor: pointer;
  color: $quinary-font;
  background-color: transparent !important;
  border: transparent !important;
}

.buttonCollapse:hover {
  cursor: pointer;
  color: $secondary-font;
  background-color: transparent !important;
  border: transparent !important;
}

.labelArticle {
  font-weight: 400;
  font-size: 16px;
}

.titleArticle {
  @extend .title;
  color: $secondary-font;
  text-align: center;
  font-weight: 600;
}

.infoContainer {
  display: flex;
  overflow: auto;

  .info {
    margin-left: 0.25rem;
  }
}

.buttonDisplay {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.buttonWidth {
  width: auto;
}

@media screen and (max-width: 767px) {
  .buttonWidth {
    width: 100%;
  }
}

.buttonAdd {
  @extend .ButtonPrimary;
  width: 100%;
}

.buttonReject {
  @extend .ButtonSecondary;
  width: 100%;
}

.articleItem {
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  min-height: 80px;
}

.selectItem:hover {
  border-bottom: 1px solid black;
}

.titleFilter {
  @extend .title;
  @extend .left;
  color: $secondary-font;
  font-size: 15px;
  padding: 0rem;
  margin: 1.5rem;
  border-bottom: 1px solid rgba(1, 50, 55, 0.2);
  align-items: center;
  font-weight: 600;
}

.alignButtons {
  text-align: left;
}
