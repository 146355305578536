@import "../style.scss";

.card {
  @extend .card;
  margin-top: 1%;
  margin-bottom: 1%;
}

@media (min-width: 768px) {
  .card {
    width: 70%;
  }
}

.Form {
  padding: 3%;

  .FormGroup {
    padding-bottom: 3%;
  }

  .Button {
    padding: 5%;
  }
}

.arrow {
  color: $primary-button;
  display: flex;
  justify-content: flex-start;
}

.title {
  @extend .title;
  color: $secondary-font;
}

.tagSelect {
  background-color: $primary-button;
}

.radio {
  @extend .center;
  font-weight: 600;
}

.tooltipQuestion {
  @extend .right;
  color: $secondary-font;
  display: flex;
  align-items: center;
}

.inputCheckbox {
  text-align: left;
}

.checkbox {
  &:hover {
    cursor: pointer;
  }
}

.labelContainer {
  display: flex;
  align-items: center;

  color: $primary-font;

  .required {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
  }
}

.invalidFeedback {
  text-align: left;
}

.checkboxItems {
  display: flex;
  align-items: center;
}

.bodyEdit {
  @extend .body;
  height: fit-content;
  overflow-y: auto;
}

.description {
  justify-content: space-between;
}