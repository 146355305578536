@import "../style.scss";
@import "../../../public/styles/breakpoints.scss";

.container {
  padding: 3%;

  @include media("<=tablet") {
    padding: 0;
  }
}

.emptyList {
  padding-top: 25px;
}

.newNetContainer {
  padding: 0.5rem 1rem;
}

.newNetLabel {
  @extend .title;
  color: $primary-font;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  padding: 0;

  @include media("<=tablet") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
}

.addIcon {
  z-index: 1;
  margin-left: -10px !important;
  height: 100% !important;
  display: inherit !important;
}

.card {
  @extend .card;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem;
}

.cardItem {
  padding: 1rem;
}

.borderTop {
  border-top: 1px solid $secondary-line;
}

.buttonAddGroup {
  background-color: $primary-button;
  cursor: pointer;
}

.buttonAddGroup:hover {
  background-color: $primary-hover-button;
  cursor: pointer;
}
