@import "../style.scss";

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: #ffffff;
    padding: 20px;
    width: 80%;
}

.title {
    // border: 1px dotted green;
    font-size: 20px;
    color: #013237;
}

.imageModalContent {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
}

.imageInput {
    // border: 1px solid red;
    margin: 1rem;
}

.aproveImageCard {
    margin: 5px 12px;
    background-color: white;
    border-radius: 8px;
    height: 315px;
    padding: 25px 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.aproveImageCard > img {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}
