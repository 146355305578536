@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "../../public/styles/colors.scss";

//GENERAL
.body {
    flex: auto;
}

Label {
    display: flex;
    justify-content: flex-start;
    font-size: 0.9rem;
    font-weight: 600;
}

input {
    border: 2px solid $quaternary-font !important;
    border-radius: 8px !important;
}

select {
    border: 2px solid $quaternary-font !important;
    border-radius: 8px !important;
}

input[type="checkbox"] {
    border-radius: 3px !important;
    position: absolute !important;
}

input[type="checkbox"]:checked {
    background-color: $secondary-font !important;
    border: 1px solid $secondary-font !important;
}

input[type="radio"] {
    position: absolute;
}

input[type="radio"]:checked {
    background-image: none !important;
    background: radial-gradient(
        $secondary-font 0%,
        $secondary-font 40%,
        transparent 50%,
        transparent
    ) !important;
    border-color: $secondary-font !important;
    border: 1px solid !important;
    color: $secondary-font !important;
    position: absolute;
}

input[type="text"] {
    font-weight: inherit !important;
    color: #212529 !important;
}

input[type="password"] {
    font-weight: inherit !important;
    color: #212529 !important;
}

::placeholder {
    color: $quinary-font !important;
    font-size: 0.9rem;
    font-weight: 500;
}

//BUTTONS
.ButtonPrimary {
    color: $tertiary-font;
    border: transparent;
    background-color: $primary-button;
}

.ButtonPrimary:active {
    background-color: $primary-hover-button;
}

.ButtonPrimary:focus {
    background-color: $primary-hover-button;
}

.ButtonPrimary:hover {
    background-color: $primary-hover-button;
}

.ButtonSecondary {
    color: $quinary-font;
    border: transparent;
    background-color: $secondary-button;
}

.ButtonSecondary:focus {
    background-color: $secondary-hover-button;
}

.ButtonSecondary:active {
    background-color: $secondary-hover-button;
}

.ButtonSecondary:hover {
    background-color: $secondary-hover-button;
}

.buttonAdd {
    @extend .ButtonPrimary;
    width: 100%;
}

.buttonReject {
    @extend .ButtonSecondary;
    width: 100%;
}

.card {
    border: transparent;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
}

.edit:hover {
    color: $primary-button;
    cursor: pointer;
}

.delete:hover {
    color: $tertiary-button;
    cursor: pointer;
}

//ALIGN
.center {
    display: flex;
    justify-content: center;
}

.left {
    display: flex;
    justify-content: flex-start;
}

.right {
    display: flex;
    justify-content: flex-end;
}

//TITLES
.title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 1rem;
}

.subtitle {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1rem;
}

.titleAdmin {
    text-align: left;
    color: $quinary-font;
    padding: 0px;
    font-size: 1.5rem;
    font-weight: 400;
}

@media screen and (max-width: 575px) {
    .titleAdmin {
        text-align: center;
        color: $quinary-font;
        padding: 0px;
        font-size: 1.5rem;
        font-weight: 400;
    }
}

//LINK
.link {
    color: inherit;
    text-decoration: inherit;
}

.link:hover {
    color: $secondary-font;
}

.navLinkActive,
.navLink {
    &:hover {
        cursor: pointer;
    }
}

.containerAdmin {
    margin-bottom: 1rem;
}

.imageModalContainer {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imageModalHeader {
    border-bottom: none;
    justify-content: center;
    color: $primary-font;
    h5 {
        font-size: 25px;
    }
}

.imageModalBody {
    text-align: center;
    font-size: 16px;
    color: $primary-font;
}

.imageModalFooter {
    display: flex;
    justify-content: center;
    border-top: none;
    font-size: 20px;
}
